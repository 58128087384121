import Simple from '@/components/task-templates/Simple'

export default [
  {
    component: Simple,
    name: 'Первое присваивание',
    text: 'Дано двузначное число \\(a\\). Запишите десятки и единицы в две новые переменные. Выведите сумму и произведение этих переменных через пробел',
    note: null,
    input: '\\(a\\) - целое положительное двузначное число',
    output: 'Сумма и произведение цифр',
    examples: [
      {
        input: '38',
        output: '11 24'
      },
      {
        input: '12',
        output: '3 2'
      }
    ]
  },
  {
    component: Simple,
    name: 'Инкремент и декремент',
    text: 'Дано число \\(a\\). Увеличьте \\(a\\) на 1, используя ++. Выведите значение \\(a\\). Затем уменьшите \\(a\\) на 1, используя --. Снова выведите значение \\(a\\)',
    note: null,
    input: '\\(a\\) - целое',
    output: 'Два числа на разных строках',
    examples: [
      {
        input: '5',
        output: '6\n5'
      },
      {
        input: '12',
        output: '13\n12'
      }
    ]
  },
  {
    component: Simple,
    name: 'Порядок при инкременте и декременте',
    text: 'Даны числа \\(a\\) и \\(b\\). В первой строке выведите ++a и --b. Во второй строке просто a и b, в третьей a++ и b--, в четвёртой просто a и b',
    note: 'Посмотрите на ответы. Понятно ли, почему так?',
    input: '\\(a\\), \\(b\\) - целые',
    output: '4 строки по два числа',
    examples: [
      {
        input: '5, 5',
        output: '6 4\n6 4\n6 4\n7 3'
      },
      {
        input: '10, 20',
        output: '11 19\n11 19\n11 19\n12 18'
      }
    ]
  },
  {
    component: Simple,
    name: 'Сложение и вычитание с присваиванием',
    text: 'Даны числа \\(a\\) и \\(b\\). Сначала увеличьте значение \\(a\\) на 2, затем уменьшите \\(b\\) на \\(a\\). Выведите получившиеся значения',
    note: 'Новые переменные не создаём, а изменяем те, которые есть. Используем += и -=',
    input: '\\(a\\), \\(b\\) - целые',
    output: 'Новые значения \\(a\\) и \\(b\\) через пробел',
    examples: [
      {
        input: '5, 9',
        output: '7 2'
      },
      {
        input: '-1, 0',
        output: '1 -1'
      },
      {
        input: '-4, -6',
        output: '-2 -4'
      }
    ]
  },
  {
    component: Simple,
    name: 'Умножение и деление с присваиванием',
    text: 'Даны числа \\(a\\) и \\(b\\). Сначала уменьшите значение \\(a\\) в 2 раза, затем увеличьте \\(b\\) в \\(a\\) раз. Выведите получившиеся значения',
    note: 'Новые переменные не создаём, а изменяем те, которые есть. Используем /= и *=',
    input: '\\(a\\), \\(b\\) - целые',
    output: 'Новые значения \\(a\\) и \\(b\\) через пробел',
    examples: [
      {
        input: '5, 9',
        output: '2.5 22.5'
      },
      {
        input: '-1, 0',
        output: '-0.5 -0'
      },
      {
        input: '-4, -6',
        output: '-2 12'
      }
    ]
  },
  {
    component: Simple,
    name: 'Остаток и степень с присваиванием',
    text: 'Даны числа \\(a\\) и \\(b\\). Сначала перезапишите значение \\(a\\) на остаток от деления на 3, затем возведите \\(b\\) в степень \\(a\\), записав результат в \\(b\\). Выведите получившиеся значения',
    note: 'Новые переменные не создаём, а изменяем те, которые есть. Используем %= и **=',
    input: '\\(a\\), \\(b\\) - целые положительные',
    output: 'Новые значения \\(a\\) и \\(b\\) через пробел',
    examples: [
      {
        input: '5, 9',
        output: '2 81'
      },
      {
        input: '1, 7',
        output: '1 7'
      },
      {
        input: '6, 10',
        output: '0 1'
      }
    ]
  },
  {
    component: Simple,
    name: 'Гипотенуза и периметр',
    text: 'Даны катеты прямоугольного треугольника \\(a\\) и \\(b\\). Найти его гипотенузу и периметр. \\(c = \\sqrt{a^2 + b^2}, P = a + b + c\\).',
    note: 'Обратите внимание, что для расчёта периметра нам нужна гипотенуза, которую нужно вычислить. Таким образом, нужно сначала создать переменную \\(c\\), записав в неё значение выражения \\(\\sqrt{a^2 + b^2}\\), а затем использовать эту переменную.',
    input: '\\(a\\), \\(b\\) - целые',
    output: 'Гипотенуза и периметр через пробел',
    examples: [
      {
        input: '3, 4',
        output: '5 12'
      },
      {
        input: '5, 7',
        output: '8.602325267042627 20.602325267042627'
      }
    ]
  },
  {
    component: Simple,
    name: 'Обмен без переменной',
    text: 'Даны числа \\(a\\) и \\(b\\). Нужно обменять значения переменных таким образом, чтобы число, которое изначально было в переменной \\(a\\), оказалось в переменной \\(b\\), а число, которое изначально было в \\(b\\), оказалось в \\(a\\), не используя новых переменных',
    note: 'Это можно сделать с помощью сложений и вычитаний, перезаписывая существующие переменные. Данный способ обмена применим только к числам',
    input: '\\(a\\), \\(b\\) - целые',
    output: 'Новые значения \\(a\\) и \\(b\\) через пробел',
    examples: [
      {
        input: '5, 7',
        output: '7 5'
      },
      {
        input: '-5, 3',
        output: '3 -5'
      }
    ]
  },
  {
    component: Simple,
    name: 'Обмен через третью переменную',
    text: 'Даны два числа \\(a\\) и \\(b\\). Нужно обменять значения переменных таким образом, чтобы число, которое изначально было в переменной \\(a\\), оказалось в переменной \\(b\\), а число, которое изначально было в \\(b\\), оказалось в \\(a\\). Для обмена нужно использовать третью переменную',
    note: null,
    input: '\\(a\\), \\(b\\) - целые',
    output: 'Новые значения \\(a\\) и \\(b\\) через пробел',
    examples: [
      {
        input: '5, 7',
        output: '7 5'
      },
      {
        input: '-5, 3',
        output: '3 -5'
      }
    ]
  },
  {
    component: Simple,
    name: 'Обмен списочным присваиванием',
    text: 'Даны два числа \\(a\\) и \\(b\\). Нужно обменять значения переменных таким образом, чтобы число, которое изначально было в переменной \\(a\\), оказалось в переменной \\(b\\), а число, которое изначально было в \\(b\\), оказалось в \\(a\\). Для обмена нужно использовать списочное присваивание',
    note: 'Списочное присваивание - это вариант записи присваивания сразу в несколько переменных. Например: [a, b] = [10, 20]. Таким образом в a попадёт 10, а в b 20',
    input: '\\(a\\), \\(b\\) - целые',
    output: 'Новые значения \\(a\\) и \\(b\\) через пробел',
    examples: [
      {
        input: '5, 7',
        output: '7 5'
      },
      {
        input: '-5, 3',
        output: '3 -5'
      }
    ]
  },
  {
    component: Simple,
    name: 'Тройной обмен через переменную',
    text: 'Даны три числа \\(a\\), \\(b\\) и \\(c\\). Нужно обменять значения переменных таким образом, чтобы значения сдвинулись влево, а первое число стало последним. То есть, было \\(a\\), \\(b\\), \\(c\\), а должно стать \\(b\\), \\(c\\), \\(a\\). Для обмена использовать дополнительную переменную',
    note: null,
    input: '\\(a\\), \\(b\\) и \\(c\\) - целые',
    output: 'Новые значения \\(a\\), \\(b\\) и \\(c\\) через пробел',
    examples: [
      {
        input: '1, 4, 7',
        output: '4 7 1'
      },
      {
        input: '10, 15, 20',
        output: '15 20 10'
      }
    ]
  },
  {
    component: Simple,
    name: 'Тройной обмен списочным присваиванием',
    text: 'Даны три числа \\(a\\), \\(b\\) и \\(c\\). Нужно обменять значения переменных таким образом, чтобы значения сдвинулись влево, а первое число стало последним. То есть, было \\(a\\), \\(b\\), \\(c\\), а должно стать \\(b\\), \\(c\\), \\(a\\). Для обмена использовать списочное присваивание',
    note: null,
    input: '\\(a\\), \\(b\\) и \\(c\\) - целые',
    output: 'Новые значения \\(a\\), \\(b\\) и \\(c\\) через пробел',
    examples: [
      {
        input: '1, 4, 7',
        output: '4 7 1'
      },
      {
        input: '10, 15, 20',
        output: '15 20 10'
      }
    ]
  },
  {
    component: Simple,
    name: 'Степени числа',
    text: 'Дано число \\(a\\). Вывести \\(a^2\\), \\(a^4\\) и \\(a^8\\), используя не более трёх операций умножения. При решении нельзя использовать дополнительные переменные и возведение в степень',
    note: 'Например, получить \\(2^2\\), \\(2^4\\) и \\(2^8\\) за три умножения можно так: \\(2 * 2 = 4\\); \\(4 * 4 = 16\\); \\(16 * 16 = 256\\).',
    input: '\\(a\\) - целое положительное число',
    output: '\\(a^2\\), \\(a^4\\) и \\(a^8\\) на разных строках',
    examples: [
      {
        input: '1',
        output: '1\n1\n1'
      },
      {
        input: '2',
        output: '4\n16\n256'
      },
      {
        input: '10',
        output: '100\r\n10000\r\n100000000'
      }
    ]
  }
]
